import * as React from "react"
import { Link, graphql, useStaticQuery } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import BackgroundImage from 'gatsby-background-image'

import Layout from "../components/layout"
import FullScreenSection from "../components/full-screen-section"
import Seo from "../components/seo"

import styled from 'styled-components'

const IndexPage = () => {

  const data = useStaticQuery(
    graphql`
      query {
        bg1:

        file(relativePath: { eq: "bg-3d-1.jpg" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }

        bg2:
        file(relativePath: { eq: "bg-3d-2.jpg" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }

      }
    `
  )

  // const data2 = useStaticQuery(
  //   graphql`
  //     query {
  //       desktop: file(relativePath: { eq: "obj.jpg" }) {
  //         childImageSharp {
  //           fluid(quality: 90, maxWidth: 1920) {
  //             ...GatsbyImageSharpFluid_noBase64
  //           }
  //         }
  //       }
  //     }
  //   `
  // )  

  const bg1 = data.bg1.childImageSharp.fluid
  const bg2 = data.bg2.childImageSharp.fluid

  return (
    <Layout>
      <Seo title="Home" />

      <FullScreenSection>

        <BackgroundImage
          Tag="section"
          className={'container-fluid fullheight-ribbon d-flex flex-column'}
          fluid={bg1}
          backgroundColor={`#040e18`}
        >

          <div className={`row d-flex flex-1`}>

            <div className={`col pt-1 d-flex flex-column`}>

              <div class="sketchfab-embed-wrapper">
                <iframe title="Mural del Paso Inferior Santa Lucía 3D." frameborder="0" allowfullscreen mozallowfullscreen="true" webkitallowfullscreen="true" allow="autoplay; fullscreen; xr-spatial-tracking" xr-spatial-tracking execution-while-out-of-viewport execution-while-not-rendered web-share src="https://sketchfab.com/models/10b9720ec26142bd9d314c1ca58d48d9/embed"></iframe>
              </div>


            </div>                        

          </div>

          <div className={`row ribbon no-gutters`}>

            <div className={`col-3 ribbon-blue`}></div>
            <div className={`col-2 ribbon-red`}></div>
            <div className={`col-4 ribbon-navy`}></div>
            <div className={`col-3 ribbon-gray`}></div>

          </div>

        </BackgroundImage>

      </FullScreenSection>

    </Layout>
  )

}

const StyledBackgroundSection = styled(IndexPage)`
  width: 100%;
  background-position: bottom center;
  background-repeat: repeat-y;
  background-size: cover;
`

export default IndexPage
